import { createContext } from "react";
import { Appointment, Customer } from "../../../interface/interface";

export interface CustomerState {
    customers: Customer[];
    selectedCustomer: Customer | undefined;
    mode: 'view' | 'create';
  }
  
export type CustomerReducerActions = {
    type: 'set_customers';
    value: Customer[];
  } | {
    type: 'set_selected_customer';
    value: Customer | null;
  } | {
    type: 'update_customer';
    value: Customer;
  } | {
    type: 'set_mode';
    value: 'view' | 'create';
  } | {
    type: 'update_selected_customer';
    value: Customer;
  } | {
    type: 'update_customer_appointment' | 'update_selected_customer_appointment' | 'create_customer_appointment' | 'create_selected_customer_appointment';
    selectedCustomerId: string;
    value: Appointment;
  } | {
    type: 'delete_selected_customer_appointment';
    deletedAppointmentId: string
    selectedCustomerId: string
  }

export const initialState: CustomerState = {
  customers: [],
  selectedCustomer: undefined,
  mode: 'view',
}
  
export const customerReducer = (
  state: CustomerState,
  action: CustomerReducerActions
) => {
  switch (action.type) {
    case 'set_customers':
      return { ...state, customers: action.value }
    case 'set_selected_customer':
      return { ...state, selectedCustomer: action.value }
    case 'update_customer':
      return { ...state, customers: state.customers.map(customer => customer._id === action.value._id ? action.value : customer) }
    case 'update_selected_customer':
      return { ...state, selectedCustomer: action.value }
    case 'set_mode':
      return { ...state, mode: action.value }
    case 'update_customer_appointment':
      return {
        ...state,
        customers: state.customers.map(customer => {
          if (customer._id === action.selectedCustomerId) {
            return {
              ...customer,
              appointments: customer.appointments.map(appointment => 
                appointment._id === action.value._id ? action.value : appointment
              )
            }
          }
          return customer;
        })
      }
    case 'update_selected_customer_appointment':
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          appointments: state.selectedCustomer?.appointments.map(appointment => 
            appointment._id === action.value._id ? action.value : appointment
          )
        }
      }
    case 'create_customer_appointment':
      return {
        ...state,
        customers: state.customers.map(customer => 
          customer._id === action.selectedCustomerId 
            ? {
                ...customer,
                appointments: [...customer.appointments, action.value]
              }
            : customer
        )
      }
    case 'create_selected_customer_appointment':
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          appointments: state.selectedCustomer?.appointments 
            ? [...state.selectedCustomer.appointments, action.value]
            : [action.value]
        }
      }
    case 'delete_selected_customer_appointment':
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          appointments: state.selectedCustomer?.appointments.filter(appointment => appointment._id !== action.deletedAppointmentId)
        },
        customers: state.customers.map(customer => {
          if (customer._id === action.selectedCustomerId) {
            return {
              ...customer,
              appointments: customer.appointments.filter(appointment => appointment._id !== action.deletedAppointmentId)
            }
          }
          return customer;
        })
      }
    
    default:
      return state
  }
}

export const CustomerContext = createContext<
| {
    state: CustomerState
    dispatch: (action: CustomerReducerActions) => void
  }
| undefined
>(undefined)