import React from "react";
import { Outlet, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "../login/login";

const Layout = () => {
  const { isAuthenticated } = useAuth0()
  return (
    <div>
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <ul className="flex h-16 items-center space-x-8">
            {isAuthenticated && (
              <>
                <li>
                  <Link to="/" className="text-gray-700 hover:text-blue-600 font-medium transition-colors duration-200">
                    Clients
                  </Link>
                </li>
                <li>
                  <Link to="/charge" className="text-gray-700 hover:text-blue-600 font-medium transition-colors duration-200">
                    Charge
                  </Link>
                </li>
                <li>
                  <Link to="/revenue" className="text-gray-700 hover:text-blue-600 font-medium transition-colors duration-200">
                    Revenue
                  </Link>
                </li>
              </>
            )}
            <li>
              <Login />
            </li>
          </ul>
        </nav>
      </header>
      <div className="mt-6">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;