import React, { useReducer } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/layout";
import Home from "./components/pages/customers/home.tsx";
import "./output.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Revenue from "./components/pages/revenue/revenue.tsx";
import { CustomerContext, customerReducer, initialState } from "./components/pages/customers/customerReducer.tsx";
import { ToastContainer } from "react-toastify";

const App = () => {

  const [state, dispatch] = useReducer(customerReducer, initialState);

  return (
    <Auth0Provider
      domain="dev-4232k1jt4m4jaytz.eu.auth0.com"
      clientId="4OnNf68xZauFsdelQEcBD2yvoMQaIzXJ"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'https://dev-4232k1jt4m4jaytz.eu.auth0.com/api/v2/'
      }}
    >
       <CustomerContext.Provider value={{ state, dispatch }}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              } />
              <Route path="customer" element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>}
              />
              <Route path="charge" element={
                <ProtectedRoute>
                  <div className="flex items-center justify-center h-screen">
                    <div className="text-center">
                      <h1 className="text-4xl font-bold text-gray-800 mb-4">🚧 Under Construction 🚧</h1>
                      <p className="text-gray-600">This page is currently being built. Please check back later.</p>
                    </div>
                  </div>
                </ProtectedRoute>}
              />
              <Route path="revenue" element={
                <ProtectedRoute>
                  <Revenue />
                </ProtectedRoute>}
              />
            </Route>
          </Routes>
        </Router>
        <ToastContainer />
      </CustomerContext.Provider>
    </Auth0Provider>
  );
};

export default App;