import React from "react"
import { Appointment } from "../../../interface/interface";
import Modal from 'react-modal';
import { STATUS_MAPPING } from "../../utils/utils";

interface AppointmentModalProps {
    isOpen: boolean;
    onClose: () => void;
    appointment: Appointment | undefined;
    onSave: (e: React.FormEvent) => void;
    isEditing: boolean;
    setEditing: (editing: boolean) => void;
    setAppointment: (appointment: Appointment) => void;
}

const AppointmentModal = ( { isOpen, onClose, appointment, onSave, isEditing, setEditing, setAppointment }: AppointmentModalProps ) => {
  const getStatusInFrench = (status: Appointment['status']) => {
    return STATUS_MAPPING[status] || status;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => { 
        onClose()
      }}
      contentLabel="Customer Info"
    >
      {/* Close button on the top right */}
      <div className="relative p-6">
        <button 
          onClick={() => { 
            onClose()
          }}
          className="absolute top-2 right-2 p-2 hover:bg-gray-100 rounded-full"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <div className="max-w-2xl mx-auto p-4">
        <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold"> Rendez-vous </h1>
        <button
          hidden={!appointment}
          onClick={() => setEditing(!isEditing)}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          {isEditing ? 'Annuler' : 'Modifier'}
        </button>
      </div>

      <form onSubmit={(e) => {
        e.preventDefault();
        onSave(e);
      }}>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Service</label>
            {isEditing ? (
              <input
                type="text"
                value={appointment?.service}
                onChange={(e) => setAppointment({ ...appointment, service: e.target.value } as Appointment)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            ) : (
              <p className="mt-1">{appointment?.service}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Date</label>
            {isEditing ? (
              <input
                type="date"
                value={appointment?.date}
                onChange={(e) => setAppointment({ ...appointment, date: e.target.value } as Appointment)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            ) : (
              <p className="mt-1">{appointment?.date}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Heure</label>
            {isEditing ? (
              <input
                type="time"
                value={appointment?.time}
                onChange={(e) => setAppointment({ ...appointment, time: e.target.value } as Appointment)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            ) : (
              <p className="mt-1">{appointment?.time}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            {isEditing ? (
              <textarea
                value={appointment?.notes || ''}
                onChange={(e) => setAppointment({ ...appointment, notes: e.target.value } as Appointment)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            ) : (
              <p className="mt-1">{appointment?.notes || 'No notes'}</p>
            )}
          </div>

        { isEditing ? (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Moyen de paiement</label>
              <select
                value={appointment?.payment || ''}
                onChange={(e) => setAppointment({ ...appointment, payment: e.target.value } as Appointment)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Sélectionner</option>
                <option value="cash">Espèces</option>
                <option value="card">Carte bancaire</option>
                <option value="check">Chèque</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Prix</label>
              <input type='number'
                value={appointment?.amount || ''}
                onChange={(e) => setAppointment({ ...appointment, amount: Number(e.target.value) } as Appointment)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>
        ) : (
          <div className="space-y-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Moyen de paiement</label>
              <p>{appointment?.payment ? {
                'cash': 'Espèces',
                'card': 'Carte bancaire',
                'check': 'Chèque'
              }[appointment.payment] : 'Non spécifié'}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Prix</label>
              <p>{appointment?.amount} Euros</p>
            </div>
          </div>
        )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Status</label>
            {isEditing ? (
              <select
                value={appointment?.status || ''}
                onChange={(e) => setAppointment({ ...appointment, status: e.target.value as Appointment['status'] } as Appointment)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Sélectionner</option>
                {Object.entries(STATUS_MAPPING).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            ) : (
              <p className="mt-1">{appointment?.status ? getStatusInFrench(appointment.status) : ''}</p>
            )}
          </div>
        </div>

        {isEditing && (
          <div className="mt-6">
            <button
              type="submit"
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            >
              Enregistrer
            </button>
          </div>
        )}
      </form>
    </div>
      </div>
    </Modal>
  )
}

export default AppointmentModal;