import React, { useContext, useState } from "react";
import { Customer } from "../../../interface/interface";
import { CustomerContext } from "./customerReducer.tsx";

const LAMBDA_ENDPOINT = process.env.REACT_APP_LAMBDA_ENDPOINT || 'http://localhost:3001';
const ENV = process.env.REACT_APP_ENV || 'dev';

export function CustomerInfo() {
  const [isUpdateable, setIsUpdateable] = useState(false);
  const context = useContext(CustomerContext);
  if (!context) {
    return null;
  }
  const { state, dispatch } = context;
  const handleInputChange = (field: keyof Customer, value: string) => {
    dispatch({ type: 'update_selected_customer', value: { ...state.selectedCustomer, [field]: value } as Customer });
    setIsUpdateable(true);
  };

  const renderField = (label: string, field: keyof Customer) => {
    if (field === 'appointments') {
      return undefined;
    }
    return (
      <div>
        <p className="text-sm text-gray-500">{label}</p>
        {state.mode === 'create' ? (
          field === 'notes' ? (
            <textarea
              value={state.selectedCustomer?.[field] || ''}
              rows={6}
              onChange={(e) => handleInputChange(field, e.target.value)}
              className="w-full p-2 border rounded-md"
            />
          ) : (
            <input
              type="text"
                value={state.selectedCustomer?.[field] || ''}
                onChange={(e) => handleInputChange(field, e.target.value)}
                className="w-full p-2 border rounded-md"
              />
            )
        ) : (
          <p className="font-medium">{state.selectedCustomer?.[field]}</p>
        )}
      </div>
    );
  };

  const updateCustomer = async () => {
    const response = await fetch(`${LAMBDA_ENDPOINT}/${ENV}/customers/${state.selectedCustomer?._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(state.selectedCustomer),
    });
    const updatedCustomer = await response.json();
    dispatch({ type: 'update_customer', value: updatedCustomer });
    dispatch({ type: 'set_mode', value: 'view' });
    setIsUpdateable(false);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Information du client</h2>
        <button
          onClick={() => dispatch({ type: 'set_mode', value: state.mode === 'view' ? 'create' : 'view' })}
          className="px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 text-white"
        >
          {state.mode === 'view' ? 'Modifier' : 'Mode non éditable'}
        </button>
      </div>

      {/* Personal Information Section */}
      <div>
        <h3 className="text-lg font-medium mb-2">Info personnelle</h3>
        <div className="grid grid-cols-2 gap-4">
          {renderField('Nom', 'name')}
          {renderField('Email', 'email')}
          {renderField('Téléphone', 'phone')}
        </div>
      </div>

      {/* Address Section */}
      <div>
        <h3 className="text-lg font-medium mb-2">Adresse</h3>
        <div className="grid grid-cols-2 gap-4">
          {renderField('Adresse', 'address')}
          {renderField('Ville', 'city')}
          {renderField('Code postal', 'zip')}
          {renderField('Pays', 'country')}
        </div>
      </div>

      {/* Additional Information */}
      <div>
        <h3 className="text-lg font-medium mb-2">Info supplémentaire</h3>
        <div className="grid grid-cols-2 gap-4">
          {state.mode === 'view' && (
            <>
              <div>
                <p className="text-sm text-gray-500">Créé le</p>
                <p className="font-medium">{new Date(state.selectedCustomer?.created_at || '').toLocaleDateString()}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Modifié le</p>
                <p className="font-medium">{new Date(state.selectedCustomer?.updated_at || '').toLocaleDateString()}</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <div className="grid">
          {renderField('Notes', 'notes')}
        </div>
      </div>

      {state.mode === 'create' && (
        <button
          disabled={!isUpdateable}
          onClick={updateCustomer}
          className={`px-4 py-2 rounded ${ isUpdateable ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-gray-500 text-gray-300 cursor-not-allowed'}`}
        >
          Enregistrer
        </button>
      )}
    </div>
  );
} 