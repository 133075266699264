import React, { useContext, useEffect, useState } from 'react';
import { CustomerList } from './customerList.tsx';
import { CustomerDetails } from './customerDetails.tsx';
import { CustomerContext } from './customerReducer.tsx';
import { api } from '../../api/api.tsx';
import { useAuth0 } from '@auth0/auth0-react';

export default function Home() {
  const { getAccessTokenSilently } = useAuth0();
  const context = useContext(CustomerContext);
  const [token, setToken] = useState<string | null>(null)
  useEffect(() => {
    const retrieveToken = async () => {
      const token = await getAccessTokenSilently()
      setToken(token)
    }
    retrieveToken()
  }, [getAccessTokenSilently])
  
  useEffect(() => {
    async function fetchData(token: string) {
      const customers = await api('customers', 'GET', null, token)
      dispatch({ type: 'set_customers', value: customers });
    }
    if (token) {  
      fetchData(token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]); // Empty array means this only runs once on mount
  
  if (!context) {
    return null;
  }

  const { dispatch } = context;

  return (
    // <CustomerContext.Provider value={{ state, dispatch }}>
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-[300px_1fr] gap-6 min-h-screen">
          <CustomerList />
          <div className="p-4">
            <CustomerDetails />
          </div>
        </div>
      </div>
    // </CustomerContext.Provider>
  );
}
