import React, { useContext, useEffect, useState } from "react";
import { Appointment } from "../../../interface/interface.ts";
import { CustomerContext } from "../customers/customerReducer.tsx";
import AppointmentModal from "./appointmentModal.tsx";
import { toast } from "react-toastify";
import { STATUS_MAPPING } from "../../utils/utils.ts";
import { api } from "../../api/api.tsx";
import { useAuth0 } from "@auth0/auth0-react";

export function AppointmentList() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);
  const context = useContext(CustomerContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [appointmentId, setAppointmentId] = useState<string | undefined>(undefined);
  const [appointment, setAppointment] = useState<Appointment | undefined>(undefined);
  
  const deleteAppointment = async (appointmentId: string) => {
    await api(`appointments/${appointmentId}`, 'DELETE', null, token)
    toast.success('Rendez vous supprimé avec succès', {autoClose: 3000});
  };

  useEffect(() => {
    const appointment = state.selectedCustomer?.appointments.find((appointment) => appointment?._id === appointmentId);
    setAppointment(appointment);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);
  
  useEffect(() => {
    const retrieveToken = async () => {
      const token = await getAccessTokenSilently()
      setToken(token)
    }
    retrieveToken()
  }, [getAccessTokenSilently])
  
  if (!context) {
    return null;
  }
  const { state, dispatch } = context;
  
  const hasNoAppointments = state.selectedCustomer?.appointments.length === 0;

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const customerId = state.selectedCustomer?._id;
      const isExistingAppointment = appointment?._id;

      const endpoint = isExistingAppointment 
        ? `appointments/${appointment._id}`
        : `customers/${customerId}/appointments`;
      const response = await api(endpoint, isExistingAppointment ? 'PUT' : 'POST', {
        ...appointment,
        status: appointment?.status || 'scheduled'
      }, token)

      if (response) {
        if (isExistingAppointment) {
          dispatch({ 
            type: 'update_selected_customer_appointment', 
            value: response, 
            selectedCustomerId: customerId!
          });
          dispatch({ 
            type: 'update_customer_appointment', 
            value: response, 
            selectedCustomerId: customerId!
            });
        } else {
          dispatch({ 
            type: 'create_selected_customer_appointment', 
            value: response, 
            selectedCustomerId: customerId!
          });
          dispatch({ 
            type: 'create_customer_appointment', 
            value: response, 
            selectedCustomerId: customerId!
          });
        }
        toast.success('Rendez vous créé avec succès', {autoClose: 3000});
      } else {
        const text = await response.text();
        toast.error(`Erreur lors de la création du rendez vous : ${text}`, {autoClose: 3000});
      }

      setAppointmentId(undefined);
      setAppointment(undefined);
      setIsEditing(false);
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Erreur lors de la création/mise à jour du rendez vous', {autoClose: 3000});
      console.error('Erreur lors de la création/mise à jour du rendez vous:', error);
    }
  };

  return (
  <>
    <div className="bg-white p-4 rounded-lg shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">
          {hasNoAppointments ? 'No appointments scheduled' : 'Rendez-vous'}
        </h3>
        <button
          onClick={() => { 
            setIsModalOpen(true)
            if (hasNoAppointments || !appointmentId) {
              setIsEditing(true)
            }
          }}
          className="p-2 rounded-full hover:bg-gray-100 text-gray-600 hover:text-gray-900"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>
      <div className="">
        {state.selectedCustomer?.appointments.map((appointment) => (
          <div 
            key={appointment._id} 
            className="border-b border-gray-100 last:border-0 cursor-pointer hover:bg-gray-50 transition-colors duration-200"
            onClick={() => { 
              setIsModalOpen(true)
              setAppointmentId(appointment._id)
            }}
          >
            <div className="flex items-center p-2" onClick={() => { 
              setIsModalOpen(true)
              setAppointmentId(appointment._id)
            }}>
              <div className="w-64">
                <p className="font-medium truncate">{appointment.service}</p>
                <p className="text-sm text-gray-500">
                  {new Date(appointment.date).toLocaleDateString()}
                </p>
              </div>
              <span className={`ml-auto mr-4 px-2 py-1 rounded-full text-sm ${
                appointment.status === 'completed' 
                  ? 'bg-green-100 text-green-800'
                  : appointment.status === 'cancelled'
                  ? 'bg-red-100 text-red-800'
                  : 'bg-blue-100 text-blue-800'
              }`}>
                { STATUS_MAPPING[appointment.status] }
              </span>
              <button
                onClick={(e) => {
                  e.stopPropagation() // Prevent modal from opening
                  deleteAppointment(appointment._id)
                  dispatch({
                    type: 'delete_selected_customer_appointment',
                    deletedAppointmentId: appointment._id,
                    selectedCustomerId: state.selectedCustomer?._id!
                  })
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
    {isModalOpen && 
      <AppointmentModal 
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setAppointmentId(undefined)
          setIsEditing(false)
        }}
        appointment={appointment}
        onSave={handleSave}
        isEditing={isEditing}
        setEditing={setIsEditing}
        setAppointment={setAppointment}
      />
    }
  </>
  );
} 