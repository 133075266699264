import React, { useContext, useEffect, useState } from "react";
import Modal from 'react-modal';
import { toast } from "react-toastify";

import { CustomerContext } from "./customerReducer.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../api/api.tsx";

export function CustomerList() {
  const { getAccessTokenSilently } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState<string | null>(null)
  const context = useContext(CustomerContext);

  useEffect(() => {
    const retrieveToken = async () => {
      const token = await getAccessTokenSilently()
      setToken(token)
    }
    retrieveToken()
  }, [getAccessTokenSilently])

  if (!context) {
    return null;
  }
  const { state, dispatch } = context;

  const deleteCustomer = async (customerId: string) => {
    await api('customers', 'DELETE', null, token)
    const customers = await api('customers', 'GET', null, token)
    dispatch({ type: 'set_customers', value: customers });
    dispatch({ type: 'set_selected_customer', value: null });
  };

  return (
    <>
      <div className="border-r border-gray-200 pr-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Clients</h2>
          <button
            onClick={() => setIsModalOpen(true) }
            className="p-2 rounded-full hover:bg-gray-100 text-gray-600 hover:text-gray-900"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
        </div>
        <ul className="space-y-1">
          {state.customers.map((customer) => (
            <li
              key={customer._id}
              onClick={() => dispatch({ type: 'set_selected_customer', value: customer })}
              className={`p-3 rounded cursor-pointer transition-colors
                ${state.selectedCustomer?._id === customer._id 
                  ? 'bg-blue-50 text-blue-600' 
                  : 'hover:bg-gray-50'
                }`}
            >
              {customer.name}
              <div className="flex justify-end mt-1">
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce client ?')) {
                      deleteCustomer(customer._id);
                    }
                  }}
                  className="px-2 py-1 text-sm text-red-600 hover:text-red-800 hover:bg-red-50 rounded transition-colors"
                >
                  <span className="flex items-center gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    Effacer
                  </span>
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Modal for creating a new customer */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Customer Info"
      >
        {/* Close button on the top right */}
        <div className="relative p-6">
          <button 
            onClick={() => setIsModalOpen(false)}
            className="absolute top-2 right-2 p-2 hover:bg-gray-100 rounded-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <h2 className="text-xl font-semibold mb-4">Créer un client</h2>
          
          <form onSubmit={async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            const customerData = {
              name: formData.get('name'),
              email: formData.get('email'),
              phone: formData.get('phone'),
              address: formData.get('address'),
              city: formData.get('city'),
              state: formData.get('state'),
              zip: formData.get('zip'),
              country: formData.get('country'),
              notes: formData.get('notes')
            };

            try {
              const response = await api('customers', 'POST', customerData, token)
              if (response) {
                setIsModalOpen(false);
                const customers = await api('customers', 'GET', null, token)
                dispatch({ type: 'set_customers', value: customers });
              } else {
                const text = await response.text();
                toast.error(`Erreur lors de la création du client : ${text}`, {autoClose: 3000});
              }
            } catch (error) {
              console.error('Error creating customer:', error);
              toast.error('Une erreur est survenue lors de la création du client', {autoClose: 3000});
            }
          }} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <input name="name" placeholder="Nom" className="p-2 border rounded" required />
              <input name="email" type="Email" placeholder="Email" className="p-2 border rounded" required />
              <input name="phone" placeholder="Téléphone" className="p-2 border rounded" required />
              <input name="address" placeholder="Adresse" className="p-2 border rounded" required />
              <input name="city" placeholder="Ville" className="p-2 border rounded" required />
              <input name="state" placeholder="State" className="p-2 border rounded" required />
              <input name="zip" placeholder="Code postal" className="p-2 border rounded" required />
              <input name="country" placeholder="Pays" className="p-2 border rounded" required />
            </div>
            <textarea name="notes" placeholder="Notes" className="w-full p-2 border rounded" rows={4} />
            <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Créer un client
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
} 