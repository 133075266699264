const LAMBDA_ENDPOINT = process.env.REACT_APP_LAMBDA_ENDPOINT || 'http://localhost:3001';
const ENV = process.env.REACT_APP_ENV || 'dev';

export const api = async (endpoint: string, method: string, body: any, token: string | null = null) => {
  const url = `${LAMBDA_ENDPOINT}/${ENV}/${endpoint}`;
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    mode: 'cors',
    ...(body && { body: JSON.stringify(body) }),
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorDetails = await response.json();
      console.error(`API Error: ${response.status}`, errorDetails);
      throw new Error(errorDetails.message || 'API Error');
    }
    return await response.json();
  } catch (err) {
    console.error('Fetch error:', err);
    throw err;
  }
};