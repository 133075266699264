import React, { useEffect, useState } from "react"
import { PieChart } from 'react-minimal-pie-chart';
import { PaymentSummary } from "../../../interface/interface";
import { api } from "../../api/api.tsx";
import { useAuth0 } from "@auth0/auth0-react";

const Revenue = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [paymentType, setPaymentType] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [revenueData, setRevenueData] = useState<PaymentSummary | undefined>(undefined);

    useEffect(() => {
        const retrieveToken = async () => {
            const token = await getAccessTokenSilently()
            setToken(token)
        }
        retrieveToken()
    }, [getAccessTokenSilently])

    const retrieveRevenueData = async () => {
        setIsLoading(true);
        const data = await api('revenue', 'POST', { startDate, endDate, paymentType }, token)
        setRevenueData(data)
        setIsLoading(false);
    }
    
    return (
        <div className="container mx-auto p-4">
            <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex gap-4 mb-6 items-end">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Date de début</label>
                        <input 
                            type="date"
                            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Date de fin</label>
                        <input
                            type="date" 
                            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Moyen de paiement</label>
                        <select 
                            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500" 
                            onChange={(e) => setPaymentType(e.target.value)}
                        >
                            <option value="">Tous</option>
                            <option value="cash">Espèces</option>
                            <option value="card">Carte bancaire</option>
                            <option value="check">Chèque</option>
                        </select>
                    </div>
                    <button
                        onClick={retrieveRevenueData}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                    >
                        Rechercher
                    </button>
                </div>

                <table className="min-w-full">
                    {isLoading ? (
                        <div className="flex justify-center items-center h-48">
                            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                        </div>
                    ) : (
                        <>
                            <thead>
                                <tr className="border-b">
                                    { startDate && endDate ? <th className="text-left py-3 px-4">Date</th> : null }
                                    <th className="text-left py-3 px-4">Nombre de client</th>
                                    <th className="text-left py-3 px-4">Nombre de rendez-vous</th>
                                    <th className="text-right py-3 px-4">Revenue total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Data rows will be populated here */}
                            </tbody>
                            <tfoot>
                                <tr className="border-t">
                                    { startDate && endDate ? <td className="py-3 px-4 font-medium">{`${startDate} - ${endDate}`}</td> : null }
                                    <td className="py-3 px-4 font-medium"> {revenueData ? `${revenueData.customerCount}` : '0'} </td>
                                    <td className="py-3 px-4 font-medium"> {revenueData ? `${revenueData.appointmentCount}` : '0'} </td>
                                    <td className="text-right py-3 px-4 font-medium">{revenueData ? `${revenueData.total} €` : '0.00 €'}</td>
                                </tr>
                            </tfoot>
                        </>
                    )}
                </table>
            </div>
            {
                revenueData?.paymentBreakdown[0] && revenueData?.paymentBreakdown[1] && revenueData?.paymentBreakdown[2] ? (
                    <PieChart
                        style={{ height: '300px', width: '300px' }}
                        data={[
                            { title: `${revenueData.paymentBreakdown[0].payment} (${revenueData.paymentBreakdown[0].amount})`, value: revenueData.paymentBreakdown[0].amount, color: '#E38627' },
                            { title: `${revenueData.paymentBreakdown[1].payment} (${revenueData.paymentBreakdown[1].amount})`, value: revenueData.paymentBreakdown[1].amount, color: '#C13C37' },
                            { title: `${revenueData.paymentBreakdown[2].payment} (${revenueData.paymentBreakdown[2].amount})`, value: revenueData.paymentBreakdown[2].amount, color: '#6A2135' },
                        ]}
                        label={({ dataEntry }) => dataEntry.title}
                        reveal={100}
                        animate={true}
                        labelStyle={{
                            fontSize: '10px',
                            fontWeight: 'bold',
                            paddingTop: '10px'
                        }}
                    />
                ) 
                : 
                null 
            }
        </div>
    )
}

export default Revenue;