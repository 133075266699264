import React, { useContext } from "react";
import { CustomerInfo } from "./customerInfo.tsx";
import { CustomerContext } from "./customerReducer.tsx";
import { AppointmentList } from "../appointment/appointmentList.tsx";

export function CustomerDetails() {
  const context = useContext(CustomerContext);
  if (!context) {
    return null;
  }
  const { state } = context;

  if (!state.selectedCustomer) {
    return <p className="text-gray-500">Select a customer to view details</p>;
  }

  return (
    <div className="space-y-3">
      <CustomerInfo />
      <AppointmentList />
    </div>
  );
} 